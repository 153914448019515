import { ApiMethodes, endpoints, JobEvaluationDropDownKeys } from "@/constants";
import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

export const dynamicReportingApi = createApi({
  reducerPath: "dynamicReportingApi ",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  }),
  tagTypes: [
    "JobEvaluations",
    "Regions",
    "Company",
    "Sectors",
    "DynamicReports",
  ],
  endpoints: builder => ({
    fetchRegions: builder.query({
      query: ({ id }) => ({
        url: endpoints.REGION.All_REGIONS(id),
        method: ApiMethodes.GET,
      }),
      providesTags: ["Regions"],
    }),
    fetchCompany: builder.query({
      query: ({ company_id }) => ({
        url: endpoints.COMPANY.COMPANY_BY_ID(company_id),
        method: ApiMethodes.GET,
      }),
      providesTags: ["Company"],
    }),
    fetchAllSectors: builder.query({
      query: () => {
        return {
          url: endpoints.SECTOR.ALL_SECTORS(),
          method: ApiMethodes.GET,
        };
      },
      providesTags: ["Sectors"],
    }),
    fetchAllDynamicReports: builder.query({
      query: ({ company_id }) => ({
        url: endpoints.DYNAMIC_REPORTING.CREATE_DYNAMIC_REPORT(company_id),
        method: ApiMethodes.GET,
      }),
      providesTags: ["DynamicReports"],
    }),

    createDynamicReport: builder.mutation({
      query: (payload: { company_id: number; body: any }) => ({
        url: endpoints.DYNAMIC_REPORTING.CREATE_DYNAMIC_REPORT(
          payload.company_id
        ),
        method: ApiMethodes.POST,
        body: payload.body,
      }),
      invalidatesTags: ["DynamicReports"],
    }),
    checkPairGroup: builder.mutation({
      query: (payload: { company_id: number; body: any }) => ({
        url: endpoints.DYNAMIC_REPORTING.CHECK_PAIR_GROUP(payload.company_id),
        method: ApiMethodes.POST,
        body: payload.body,
      }),
      invalidatesTags: ["DynamicReports"],
    }),
  }),
});

export const {
  useFetchRegionsQuery,
  useFetchCompanyQuery,
  useFetchAllSectorsQuery,
  useFetchAllDynamicReportsQuery,
  useCreateDynamicReportMutation,
  useCheckPairGroupMutation,
} = dynamicReportingApi;
