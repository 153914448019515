import { useDispatch } from "react-redux";
import { Button, Col, Row, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "@hooks";
import {
  handleRewardElementsDetails,
  handleStage,
  resetDynamicReporting,
} from "@store/dynamicReporting";
import { DynamicReportingStages, JobEvaluationStages } from "@types";
import Checkbox from "antd/lib/checkbox/Checkbox";

const { Option } = Select;

const RewardElementsStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { rewardElementsDetails, regionCountryDetails } = useTypedSelector(
    state => state?.dynamicReporting
  );

  const {
    isRewardBy_basicPay,
    isRewardBy_guaranteeCash,
    isRewardBy_ttc_Acctual,
    isRewardBy_tdc_Acctual,
    isRewardBy_tr_Acctual,
    isRewardBy_ttc_Target,
    isRewardBy_tdc_Target,
    isRewardBy_tr_Target,
  } = rewardElementsDetails;

  const { isReportOnTomData, isReportOnPayrollData } = regionCountryDetails;

  const handleChange = (event: any) => {
    dispatch(
      handleRewardElementsDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  const { dynamicReportSteps } = useTypedSelector(
    state => state?.dynamicReporting
  );
  const handleSubmit = async () => {
    if (dynamicReportSteps) {
      return dispatch(handleStage(DynamicReportingStages.PERCENTILE));
    }
  };

  const disabled =
    !isRewardBy_basicPay &&
    !isRewardBy_guaranteeCash &&
    !isRewardBy_ttc_Acctual &&
    !isRewardBy_tdc_Acctual &&
    !isRewardBy_tr_Acctual &&
    !isRewardBy_ttc_Target &&
    !isRewardBy_tdc_Target &&
    !isRewardBy_tr_Target;

  const anyReportOnTypeSelected = isReportOnTomData || isReportOnPayrollData;

  const conditionalRewardElement = () => {
    if (isReportOnPayrollData) {
      return <></>;
    }
    if (isReportOnTomData) {
    }
    if (isReportOnTomData && isReportOnPayrollData) {
      return <></>;
    }
  };

  return (
    <>
      <Row className="offers__form__header">
        <div className="sub-heading">Choose Reward Elementss</div>
      </Row>
      <div className="offers__form__fields">
        {anyReportOnTypeSelected && (
          <>
            <Row>
              <Col span={14}>
                <Checkbox
                  checked={isRewardBy_basicPay}
                  onChange={(e: any) => {
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  className="mt-32"
                  name="isRewardBy_basicPay"
                >
                  Basic Pay
                </Checkbox>
              </Col>
            </Row>
            <Row justify="space-between" className="mb-32">
              <Col span={14}>
                <Checkbox
                  checked={isRewardBy_guaranteeCash}
                  onChange={(e: any) => {
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  className="mt-32"
                  name="isRewardBy_guaranteeCash"
                >
                  Guaranteed Cash
                </Checkbox>
              </Col>
            </Row>
          </>
        )}
        {isReportOnPayrollData && (
          <>
            <Row>
              <Col span={14}>
                <Checkbox
                  checked={isRewardBy_ttc_Acctual}
                  onChange={(e: any) => {
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  className="mt-32"
                  name="isRewardBy_ttc_Acctual"
                >
                  TTC - Actual (Actual Total Cash)
                </Checkbox>
              </Col>
            </Row>
          </>
        )}
        {false && (
          <>
            <Row>
              <Col span={14}>
                <Checkbox
                  checked={isRewardBy_tdc_Acctual}
                  onChange={(e: any) => {
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  className="mt-32"
                  name="isRewardBy_tdc_Acctual"
                >
                  TDC - Actual (Total Direct Compensation - Actual)
                </Checkbox>
              </Col>
            </Row>
            <Row justify="space-between" className="mb-32">
              <Col span={14}>
                <Checkbox
                  checked={isRewardBy_tr_Acctual}
                  onChange={(e: any) => {
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  className="mt-32"
                  name="isRewardBy_tr_Acctual"
                >
                  TR Actual (Total Remuneration - Actual)
                </Checkbox>
              </Col>
            </Row>
          </>
        )}
        {anyReportOnTypeSelected && (
          <>
            <Row>
              <Col span={14}>
                <Checkbox
                  checked={isRewardBy_ttc_Target}
                  onChange={(e: any) => {
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  className="mt-32"
                  name="isRewardBy_ttc_Target"
                >
                  TTC - Target (Target Total Cash)
                </Checkbox>
              </Col>
            </Row>
            <Row justify="space-between" className="mb-32">
              <Col span={14}>
                <Checkbox
                  checked={isRewardBy_tdc_Target}
                  onChange={(e: any) => {
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  className="mt-32"
                  name="isRewardBy_tdc_Target"
                >
                  TDC - Target (Total Direct Compensation - Target)
                </Checkbox>
              </Col>
            </Row>
          </>
        )}

        {isReportOnTomData && (
          <>
            <Row>
              <Col span={14}>
                <Checkbox
                  checked={isRewardBy_tr_Target}
                  onChange={(e: any) => {
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  className="mt-32"
                  name="isRewardBy_tr_Target"
                >
                  TR - Target (Total Remuneration - Target)
                </Checkbox>
              </Col>
            </Row>
          </>
        )}
      </div>
      <div className="offers__form__fields">
        <Row className="offers__form__fields__btns">
          <Button
            size="large"
            className="secondary-btn"
            onClick={() =>
              dispatch(
                handleStage(
                  DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY
                )
              )
            }
          >
            Back
          </Button>
          <Button
            disabled={disabled}
            onClick={() => handleSubmit()}
            size="large"
            className="secondary-btn"
          >
            Next
          </Button>
          <Button
            onClick={() => {
              dispatch(resetDynamicReporting());
            }}
            size="large"
            className="secondary-btn"
          >
            Cancel
          </Button>
        </Row>
      </div>
    </>
  );
};

export default RewardElementsStep;
