import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from "antd";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "@hooks";
import { handleDecisionMakingImpact } from "@store/jobEvaluation";
import { DynamicReportingStages, ISubJobFunctionItem } from "@types";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  handleRegionCountryDetails,
  handleStage,
  ReportOn,
  resetDynamicReporting,
} from "@store/dynamicReporting";
import { useFetchRegionsQuery } from "@/services";
import { useEffect, useState } from "react";
import moment from "moment";
import { disabledDates } from "@/utils";

const { Option } = Select;

const RegionAndCountryStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useTypedSelector(state => state.auth);
  const company_id = user?.company?.id || 0;

  const { regionCountryDetails } = useTypedSelector(
    state => state?.dynamicReporting
  );
  const {
    regions,
    isAllRegionsSelected,
    countries,
    isAllCountriesSelected,
    isReportByGrade,
    isReportByTitle,
    isReportOnTomData,
    isReportOnPayrollData,
    reportFrom,
    reportTill,
  } = regionCountryDetails;

  function handleRegionChangeImpactOnCountries(regionReceived: number[]) {
    let listOfCountries: ISubJobFunctionItem[] = [];

    //set countries list as per regions
    setcountriesByRegionsData([]);
    regionsFetchedData.forEach(
      (eachRegionRedails: { name: string; id: number; countries: [] }) => {
        if (regionReceived.includes(eachRegionRedails.id)) {
          listOfCountries = [
            ...listOfCountries,
            ...eachRegionRedails.countries,
          ];
          setcountriesByRegionsData(listOfCountries);
        }
      }
    );

    //set selected countries as per regions
    let finalSelectedCountry = countries.filter(
      (eachSelectedCountryId: number) => {
        return listOfCountries.length
          ? listOfCountries.find(x => x.id === eachSelectedCountryId)
          : false;
      }
    );

    handleChange({
      target: {
        name: "countries",
        value: finalSelectedCountry,
      },
    });
  }

  const { data: regionsData, isLoading: isLoadingRegions } =
    useFetchRegionsQuery({
      id: company_id,
    });
  const { data: regionsFetchedData } = regionsData || { data: [] };
  const [countriesByRegionsData, setcountriesByRegionsData] = useState<
    ISubJobFunctionItem[]
  >([]);

  const { dynamicReportSteps } = useTypedSelector(
    state => state?.dynamicReporting
  );

  const handleChange = (event: any) => {
    dispatch(
      handleRegionCountryDetails({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleSubmit = async () => {
    if (dynamicReportSteps) {
      return dispatch(
        handleStage(DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY)
      );
    }
  };

  useEffect(() => {
    if (!isLoadingRegions) {
      handleRegionChangeImpactOnCountries(regions);
      debugger;
    }
  }, [regions]);

  useEffect(() => {
    if (!isLoadingRegions) {
      // if (isAllRegionsSelected) {
      //   const allRegions = regionsFetchedData.map(
      //     ({ name, id }: { name: string; id: number }) => id
      //   );
      //   handleChange({
      //     target: {
      //       name: "regions",
      //       value: allRegions,
      //     },
      //   });
      // }
      // if (!isAllRegionsSelected) {
      //   handleChange({
      //     target: {
      //       name: "regions",
      //       value: [],
      //     },
      //   });
      // }
      // if (isAllCountriesSelected) {
      //   const allCountries = countriesByRegionsData.map(
      //     ({ name, id }: { name: string; id: number }) => id
      //   );
      //   handleChange({
      //     target: {
      //       name: "countries",
      //       value: allCountries,
      //     },
      //   });
      // }
      // if (!isAllCountriesSelected) {
      //   handleChange({
      //     target: {
      //       name: "countries",
      //       value: [],
      //     },
      //   });
      // }
    }
  }, [isAllRegionsSelected, isAllCountriesSelected, isLoadingRegions]);

  function disabledDate(current: any) {
    return disabledDates(current, moment(moment(reportFrom)).endOf("day"));
  }

  const disabled =
    !regions.length ||
    !countries.length ||
    reportFrom === "" ||
    reportTill === "" ||
    (!isReportByGrade && !isReportByTitle) ||
    (!isReportOnTomData && !isReportOnPayrollData);

  return (
    <>
      <Row className="offers__form__header">
        <div className="sub-heading">Select Your Region & Country</div>
      </Row>
      <div className="offers__form__fields">
        <Row justify="space-between" className="mb-32">
          <Col span={9}>
            <label>Regions</label>
            <Select
              loading={isLoadingRegions}
              disabled={isAllRegionsSelected}
              showArrow={true}
              size="large"
              showSearch={true}
              placeholder="Select regions from here..."
              mode="multiple"
              maxTagCount="responsive"
              value={regions}
              onChange={(regionReceived: number[]) => {
                handleChange({
                  target: {
                    name: "regions",
                    value: regionReceived,
                  },
                });
              }}
            >
              {regionsFetchedData?.map(
                ({ name, id }: { name: string; id: number }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                )
              )}
            </Select>
            {regionsFetchedData.length > 0 && (
              <Checkbox
                checked={isAllRegionsSelected}
                onChange={(e: any) => {
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  });

                  if (e.target.checked) {
                    const allRegions = regionsFetchedData.map(
                      ({ name, id }: { name: string; id: number }) => id
                    );

                    handleChange({
                      target: {
                        name: "regions",
                        value: allRegions,
                      },
                    });
                  }
                  if (!e.target.checked) {
                    handleChange({
                      target: {
                        name: "regions",
                        value: [],
                      },
                    });
                  }
                }}
                className="mt-32"
                name="isAllRegionsSelected"
              >
                Select All Regions
              </Checkbox>
            )}
          </Col>
          <Col span={6}>
            <label>Report From</label>
            <DatePicker
              className="width-100"
              value={!reportFrom ? undefined : moment(reportFrom)}
              onChange={(date, dateString) => {
                handleChange({
                  target: {
                    name: "reportFrom",
                    value: dateString
                      ? (moment(dateString)
                          .format("YYYY-MM-DD")
                          ?.toString() as string)
                      : "",
                  },
                });
              }}
              placeholder="Report From Date"
            />
          </Col>
          <Col span={6}>
            <label>Report Till</label>
            <DatePicker
              disabled={!reportFrom}
              disabledDate={disabledDate}
              className="width-100"
              value={!reportTill ? undefined : moment(reportTill)}
              onChange={(date, dateString) => {
                handleChange({
                  target: {
                    name: "reportTill",
                    value: dateString
                      ? (moment(dateString)
                          .format("YYYY-MM-DD")
                          ?.toString() as string)
                      : "",
                  },
                });
              }}
              placeholder="Report Till Date"
            />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-32">
          <Col span={9}>
            <label>Countries</label>
            <Select
              loading={isLoadingRegions}
              disabled={isAllCountriesSelected}
              showArrow={true}
              size="large"
              showSearch={true}
              placeholder="Select countries from here..."
              mode="multiple"
              maxTagCount="responsive"
              value={countries}
              onChange={(countriesReceived: number[]) => {
                handleChange({
                  target: {
                    name: "countries",
                    value: countriesReceived,
                  },
                });
              }}
            >
              {countriesByRegionsData?.map(
                ({ name, id }: { name: string; id: number }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                )
              )}
            </Select>
            {countriesByRegionsData.length > 0 && (
              <Checkbox
                checked={isAllCountriesSelected}
                onChange={(e: any) => {
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  });

                  if (e.target.checked) {
                    const allCountries = countriesByRegionsData.map(
                      ({ name, id }: { name: string; id: number }) => id
                    );

                    handleChange({
                      target: {
                        name: "countries",
                        value: allCountries,
                      },
                    });
                  }
                  if (!e.target.checked) {
                    handleChange({
                      target: {
                        name: "countries",
                        value: [],
                      },
                    });
                  }
                }}
                className="mt-32"
                name="isAllCountriesSelected"
              >
                Select All Countries
              </Checkbox>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={9}>
            <label>Generate Report By</label>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Checkbox
              checked={isReportByGrade}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              className="mt-32"
              name="isReportByGrade"
            >
              Grade
            </Checkbox>
          </Col>
        </Row>
        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <Checkbox
              checked={isReportByTitle}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              className="mt-32"
              name="isReportByTitle"
            >
              Title
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col span={9}>
            <label>Generate Report On</label>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Checkbox
              checked={isReportOnTomData}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              className="mt-32"
              name="isReportOnTomData"
            >
              TOM Offer Data
            </Checkbox>
          </Col>
        </Row>
        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <Checkbox
              checked={isReportOnPayrollData}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              className="mt-32"
              name="isReportOnPayrollData"
            >
              Payroll Data
            </Checkbox>
          </Col>
        </Row>
      </div>
      <div className="offers__form__fields">
        <Row className="offers__form__fields__btns">
          <Button
            disabled={disabled}
            onClick={() => handleSubmit()}
            size="large"
            className="secondary-btn"
          >
            Next
          </Button>
          <Button
            onClick={() => {
              dispatch(resetDynamicReporting());
            }}
            size="large"
            className="secondary-btn"
          >
            Cancel
          </Button>
        </Row>
      </div>
    </>
  );
};

export default RegionAndCountryStep;
