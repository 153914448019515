import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authApi, dynamicReportingApi } from "@services";
import { authReducer } from "./auth";
import { countriesReducer } from "./countries";
import {
  ISectorsState,
  ISubAdminReducer,
  IAuthState,
  IBusinessUnitState,
} from "@types";
import { jobEvaluationReducer } from "./jobEvaluation";
import { jobGradeMappingApi } from "@/services/jobGradeMapping";
import { dynamicReportingReducer } from "./dynamicReporting";

export interface ICombineReducerProps {
  auth: IAuthState;
  businessUnit: IBusinessUnitState;
  subAdmin: ISubAdminReducer;
  sectors: ISectorsState;
}

const appReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [dynamicReportingApi.reducerPath]: dynamicReportingApi.reducer,
  auth: authReducer,
  countries: countriesReducer,
  jobEvaluation: jobEvaluationReducer,
  dynamicReporting: dynamicReportingReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth"],
};

const rootReducer = (state: any, action: any) => {
  if (
    action?.meta?.arg?.endpointName === "logout" &&
    action?.type === "authApi /executeMutation/fulfilled"
  ) {
    state = {} as RootState;
    localStorage.clear();
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools:
    process.env.NODE_ENV !== "production" && typeof window !== "undefined",
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    authApi.middleware,
    dynamicReportingApi.middleware,
  ],
});

window.store = store;
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
