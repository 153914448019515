import { useDispatch } from "react-redux";
import { Button, Col, InputNumber, Row, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "@hooks";
import {
  handlePercentileDetails,
  handleStage,
  resetDynamicReporting,
} from "@store/dynamicReporting";
import { DynamicReportingStages, JobEvaluationStages } from "@types";
import Checkbox from "antd/lib/checkbox/Checkbox";

const { Option } = Select;

const PercentileStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { percentileDetails } = useTypedSelector(
    state => state?.dynamicReporting
  );
  const { isp10, isp25, isp50, isp75, isp90, isCustom, customPercentile } =
    percentileDetails;

  const handleChange = (event: any) => {
    dispatch(
      handlePercentileDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  const { dynamicReportSteps } = useTypedSelector(
    state => state?.dynamicReporting
  );
  const handleSubmit = async () => {
    if (dynamicReportSteps) {
      return dispatch(handleStage(DynamicReportingStages.AGING));
    }
  };

  const disabled =
    (!isp10 && !isp25 && !isp50 && !isp75 && !isp90 && !isCustom) ||
    (isCustom && !customPercentile);
  return (
    <>
      <Row className="offers__form__header">
        <div className="sub-heading">Percentile Selection</div>
      </Row>
      <div className="offers__form__fields">
        <Row>
          <Col span={3}>
            <Checkbox
              checked={isp10}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              className="mt-32"
              name="isp10"
            >
              P10
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <Checkbox
              checked={isp25}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              className="mt-32"
              name="isp25"
            >
              P25
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Checkbox
              checked={isp50}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              className="mt-32"
              name="isp50"
            >
              P50
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Checkbox
              checked={isp75}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              className="mt-32"
              name="isp75"
            >
              P75
            </Checkbox>
          </Col>
        </Row>

        <Row>
          <Col span={7}>
            <Checkbox
              checked={isp90}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              className="mt-32"
              name="isp90"
            >
              P90
            </Checkbox>
          </Col>
        </Row>

        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <Checkbox
              checked={isCustom}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });

                if (!e.target.checked) {
                  handleChange({
                    target: {
                      name: "customPercentile",
                      value: undefined,
                    },
                  });
                }
              }}
              className="mt-32"
              name="isCustom"
            >
              Custom
            </Checkbox>
          </Col>
        </Row>

        {isCustom && (
          <Row justify="space-between" className="modal__row">
            <Col span={15}>
              <label>Custom Percentile Value</label>
            </Col>
            <Col span={15}>
              <InputNumber
                size="large"
                min={0}
                max={100}
                value={customPercentile}
                onChange={(value: any) => {
                  handleChange({
                    target: {
                      name: "customPercentile",
                      value: value ? value : undefined,
                    },
                  });
                }}
              />
            </Col>
          </Row>
        )}
      </div>
      <div className="offers__form__fields">
        <Row className="offers__form__fields__btns">
          <Button
            size="large"
            className="secondary-btn"
            onClick={() =>
              dispatch(handleStage(DynamicReportingStages.REWARD_ELEMENTS))
            }
          >
            Back
          </Button>
          <Button
            disabled={disabled}
            onClick={() => handleSubmit()}
            size="large"
            className="secondary-btn"
          >
            Next
          </Button>
          <Button
            onClick={() => {
              dispatch(resetDynamicReporting());
            }}
            size="large"
            className="secondary-btn"
          >
            Cancel
          </Button>
        </Row>
      </div>
    </>
  );
};

export default PercentileStep;
