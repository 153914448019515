const admin = {};

const client = {
  root: "/",
  dashboard: "/client/reports",
  auth: {
    login: "/client/login",
    forgot_password: "/client/forgot-password",
    reset_password: "/client/reset-password",
  },

  dynamicReporting: {
    listing: () => "/client/reports",
    create: () => "/client/generate-dynamic-report",
  },
};

export { admin, client };
