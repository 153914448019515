import ClientLogin from "@pages/client/Auth/Login";
import ClientResetPassword from "@pages/client/Auth/ResetPassword";
import ClientForgotPassword from "@pages/client/Auth/ForgotPassword";
import { paths, IRoute, permissions } from "@router";
import { UserRoles } from "@/constants";
import DynamicReporting from "@/pages/client/DynamicReporting";
import DynamicReportingSteps from "@/pages/client/DynamicReporting/DynamicReportingSteps";

export const admin_routeConfig: IRoute[] = [];

export const client_routeConfig: IRoute[] = [
  {
    path: paths.client.auth.login,
    component: ClientLogin,
    key: "Client Login",
    isPrivate: false,
    isCompany: true,
    allowedRoles: [],
  },

  {
    path: paths.client.auth.forgot_password,
    component: ClientForgotPassword,
    key: "Client Forgot Password",
    isPrivate: false,
    isCompany: true,
    allowedRoles: [],
  },
  {
    path: paths.client.auth.reset_password,
    component: ClientResetPassword,
    key: "Reset Password",
    isPrivate: false,
    isCompany: true,
    allowedRoles: [],
  },
  {
    path: paths.client.dynamicReporting.listing(),
    component: DynamicReporting,
    key: "Job Creation",
    exact: true,
    breadcrumb: "Dynamic Reporting / Reports",
    isPrivate: true,
    isCompany: true,
    permission: permissions.CREATE_JOB_FOR_EVALUATION,
    allowedRoles: [
      UserRoles.COMPANY_ADMIN,
      UserRoles.COMPANY_BUSINESS_ACCESS,
      UserRoles.COMPANY_SUPER_USER,
      UserRoles.COMPANY_USER,
    ],
  },
  {
    path: paths.client.dynamicReporting.create(),
    component: DynamicReportingSteps,
    key: "Dynamic Reporting",
    exact: true,
    breadcrumb: "Dynamic Reporting / Create New Dynamic Report",
    isPrivate: true,
    isCompany: true,
    permission: permissions.CREATE_EVALUATION,
    allowedRoles: [
      UserRoles.COMPANY_ADMIN,
      UserRoles.COMPANY_BUSINESS_ACCESS,
      UserRoles.COMPANY_SUPER_USER,
      UserRoles.COMPANY_USER,
    ],
  },

  {
    path: paths.client.root,
    component: DynamicReporting,
    key: "Root",
    breadcrumb: "Dynamic Reporting / Reports",
    isPrivate: true,
    isCompany: true,
    permission: permissions.CREATE_JOB_FOR_EVALUATION,
    allowedRoles: [
      UserRoles.COMPANY_ADMIN,
      UserRoles.COMPANY_BUSINESS_ACCESS,
      UserRoles.COMPANY_SUPER_USER,
      UserRoles.COMPANY_USER,
    ],
  },
];
